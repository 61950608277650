import React from 'react';
import bgimg from '../../img/Homepage.jpg';
import { Link } from 'react-router-dom';
import logo from '../../img/anish logo.png';

function HomePage() {
  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${bgimg})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50" />
      <div className="relative z-10 text-center text-white ml-0 md:ml-96 p-6 mx-4">
      <div className="mb-6">
          <img 
            src={logo} 
            alt="Anish Multispeciality Hospital Logo" 
            className="mx-auto w-32 md:w-48" // Adjust width as needed
          />
        </div>
        <h2 className="text-3xl md:text-5xl font-bold mb-4">
          Your Health, Our Commitment
        </h2>
        <p className="text-lg md:text-2xl mb-6">
          Comprehensive Care for Every Family Member, Every Day
        </p>
        <Link to="/contact">
          <button className="bg-red-600 hover:bg-red-700 text-white font-semibold uppercase py-3 px-8 rounded-full shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 duration-300">
            Book An Appointment
          </button>
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
