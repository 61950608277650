import React from 'react';
import bgimg from '../../../img/pattern-doddle-sketch.jpg';
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail, IoMdCall } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

function Appointment() {
    return (
        <div
            className='relative py-8 px-8 mt-20'
            style={{
                backgroundImage: `url(${bgimg})`,

            }}
        >
            {/* Gradient Overlay */}
            <div className='absolute inset-0 bg-gradient-to-r from-[#009F98] to-[#004D9B] opacity-90'></div>

            {/* Contact Info Section */}
            <div className='relative bg-white shadow-lg rounded-lg p-8 -mt-28 mb-12 max-w-6xl mx-auto'>
                <div className='flex flex-col md:flex-row justify-around items-center text-gray-900'>
                    {/* Call Us Section */}
                    <div className='text-center mb-6 md:mb-0'>
                        <IoMdCall className='text-4xl text-[#004D9B] mb-2 mx-auto' />
                        <h4 className='text-xl font-semibold text-[#004D9B]'>Call Us On</h4>
                        <p className='text-lg font-light'>9511834123</p>
                    </div>

                    {/* Email Us Section */}
                    <div className='text-center mb-6 md:mb-0'>
                        <IoIosMail className='text-4xl text-[#004D9B] mb-2 mx-auto' />
                        <h4 className='text-xl font-semibold text-[#004D9B]'>Email Us</h4>
                        <p className='text-lg font-light'>anishmultihospital@gmail.com</p>
                    </div>

                    {/* Our Address Section */}
                    <div className='text-center'>
                        <IoLocationSharp className='text-4xl text-[#004D9B] mb-2 mx-auto' />
                        <h4 className='text-xl font-semibold text-[#004D9B]'>Our Address</h4>
                        <p className='text-lg font-light'>Terwad Road, Kurundwad, Maharashtra 416106</p>
                    </div>
                </div>
            </div>


            <div className='text-center max-w-full mx-auto relative z-10 text-white'>
                <h2 className='text-2xl md:text-3xl font-semibold mb-4'>Need to check up?</h2>
                <h1 className='text-4xl md:text-5xl font-bold leading-tight mb-6'>
                    Just Make An Appointment & You're Done!
                </h1>
                <Link to='/contact'><button className='bg-red-600 hover:bg-red-700 text-white font-semibold uppercase py-3 px-8 rounded-full shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 duration-300'>
                    Book An Appointment
                </button></Link>
            </div>
        </div>
    );
}

export default Appointment;
