import React from 'react';
import img1 from '../../../img/Maternity_and_Infertility.png';
import img2 from '../../../img/ear-nose-throat-.png';
import img3 from '../../../img/cancer.png';
import img4 from '../../../img/ortho.png';
import img5 from '../../../img/g_ward.png';
import bgimg from '../../../img/dropdown-bg.png';

function Service() {
    return (
        <div className="bg-gray-50 py-10">
            <div className="text-center mb-10">
                <h1 className="text-4xl font-bold text-gray-800">Our Services</h1>
                <p className="text-lg text-gray-600 mt-4">Offering specialized care in multiple departments for all your health needs</p>
            </div>

            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-10 container mx-auto px-4">
                {/* Service 1 */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden relative"
                    style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="bg-white bg-opacity-30 absolute inset-0"></div>
                    <div className="relative flex items-center p-4 z-10">
                        <img src={img1} alt="Maternity and Infertility" className="w-1/3 h-40 object-cover" />
                        <div className="ml-4">
                            <h2 className="text-2xl font-bold text-gray-800 mb-2">Maternity and Infertility Treatment and Care</h2>
                        </div>
                    </div>
                    <div className="relative p-6 z-10">
                        <p className="text-gray-600">At Anish Multispeciality Hospital, we offer comprehensive maternity services, from prenatal to postnatal care. Our experienced obstetricians and gynecologists provide personalized treatment for expectant mothers, ensuring a smooth and safe delivery. Additionally, our infertility specialists are here to assist couples with fertility challenges, offering advanced diagnostics and treatment options to help them achieve their dreams of parenthood.</p>
                    </div>
                </div>

                {/* Service 2 */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden relative"
                    style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="bg-white bg-opacity-30 absolute inset-0"></div>
                    <div className="relative flex items-center p-4 z-10">
                        <img src={img2} alt="Ear, Nose, and Throat" className="w-1/3 h-40 object-cover" />
                        <div className="ml-4">
                            <h2 className="text-2xl font-bold text-gray-800 mb-2">Ear, Nose, and Throat Specialist</h2>
                        </div>
                    </div>
                    <div className="relative p-6 z-10">
                        <p className="text-gray-600">Our ENT department provides specialized care for conditions related to the ear, nose, and throat. Whether it’s treating chronic ear infections, sinus problems, or throat disorders, our team of experienced ENT specialists uses the latest technology to diagnose and treat these conditions effectively, ensuring patients can breathe and hear better.</p>
                    </div>
                </div>

                {/* Service 3 */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden relative"
                    style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="bg-white bg-opacity-30 absolute inset-0"></div>
                    <div className="relative flex items-center p-4 z-10">
                        <img src={img3} alt="Cancer Treatment" className="w-1/3 h-40 object-cover" />
                        <div className="ml-4">
                            <h2 className="text-2xl font-bold text-gray-800 mb-2">Cancer Diagnosis and Treatment</h2>
                        </div>
                    </div>
                    <div className="relative p-6 z-10">
                        <p className="text-gray-600">We understand the importance of early detection in fighting cancer. At Anish Multispeciality Hospital, we offer comprehensive cancer diagnostic services, including advanced imaging and pathology. Our oncology team provides personalized treatment plans, including surgery, chemotherapy, and radiation therapy, aimed at providing the best possible outcomes for our patients.</p>
                    </div>
                </div>

                {/* Service 4 */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden relative"
                    style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="bg-white bg-opacity-30 absolute inset-0"></div>
                    <div className="relative flex items-center p-4 z-10">
                        <img src={img4} alt="Orthopedic Treatment" className="w-1/3 h-40 object-cover" />
                        <div className="ml-4">
                            <h2 className="text-2xl font-bold text-gray-800 mb-2">Orthopedic Treatment</h2>
                        </div>
                    </div>
                    <div className="relative p-6 z-10">
                        <p className="text-gray-600">Our orthopedic department specializes in diagnosing and treating conditions related to bones, joints, and muscles. Whether you’re dealing with a fracture, arthritis, or a sports injury, our experienced orthopedic surgeons and physiotherapists provide both surgical and non-surgical treatments to help you regain mobility and live pain-free.</p>
                    </div>
                </div>

                {/* Service 5 */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden relative"
                    style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="bg-white bg-opacity-30 absolute inset-0"></div>
                    <div className="relative flex items-center p-4 z-10">
                        <img src={img5} alt="General Medicine and Surgical Ward" className="w-1/3 h-40 object-cover" />
                        <div className="ml-4">
                            <h2 className="text-2xl font-bold text-gray-800 mb-2">General Medicine and Surgical Ward</h2>
                        </div>
                    </div>
                    <div className="relative p-6 z-10">
                        <p className="text-gray-600">The General Medicine and Surgical Ward at Anish Multispeciality Hospital provides round-the-clock care for patients with a wide range of medical and surgical needs. Our highly skilled team of doctors, nurses, and support staff are dedicated to offering the best possible care, from initial diagnosis to post-surgical recovery, ensuring a smooth and comfortable experience for all patients.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
