import React from 'react'
import About from '../About/About'
import WhyChoose from './Service/WhyChoose'
import Service from './Service/Service'
import Appointment from './Service/Appoitment'
import HomePage from './HomePage'

function Home() {
  return (
    <div>
      <HomePage/>
      <About/>
     <WhyChoose/>
     <Service/>
     <Appointment/>
    </div>
  )
}

export default Home