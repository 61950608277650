import React from 'react';
import bgimg from '../../img/HOSPITAL.png';
import doctor from '../../img/cheerful-doctor-making-notes-looking-away.jpg';
import aboutimg from '../../img/bg-55.svg';
import aboutdoctor from '../../img/search_speciality_bg_img.png';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div className="bg-white py-10">
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-start justify-between">
        {/* Left Side: Text and Doctor Info */}
        <div className="md:flex-1 md:max-w-7xl space-y-6 md:pr-10 ">
          <div className="text-center md:text-left">
            <h1 className="uppercase text-3xl md:text-4xl font-extrabold text-gray-900 tracking-wide">
              Introducing
            </h1>
            <h4 className="text-2xl md:text-3xl font-semibold text-[#009F98] mt-4">
              Anish Multispeciality Hospital
            </h4>
          </div>

          <div className="relative flex flex-col items-center md:items-start">
            <div className="w-48 h-48 bg-indigo-100 rounded-full shadow-2xl flex items-center justify-center z-20 mb-4">
              <img
                src={doctor}
                alt="Dr. Avinash Kognole"
                className="w-48 h-48 rounded-full object-cover border-4 border-white"
              />
            </div>

            <div className="text-center md:text-left">
              <h1 className="text-3xl font-bold text-gray-800">Dr. Avinash Kognole</h1>
              <p className="text-lg font-medium text-gray-600">
                MBBS <span className="text-indigo-500">DGO</span>
              </p>
            </div>

            <div className="mt-6 text-gray-700 space-y-4 max-w-full"
              style={{ backgroundImage: `url(${aboutimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

              <p className="text-lg leading-relaxed text-left">
                Anish Multispeciality Hospital was established by Dr. Avinash Kognole on 24th December 2006 as a 10-bed maternity and surgical facility in Kurundwad. Over the years, the hospital expanded its services and relocated to a modern facility on 6th May 2015, offering comprehensive healthcare across multiple specialties.
              </p>
              <p className="text-lg leading-relaxed text-left">
                With a focus on Gynecology, Obstetrics, General Surgery, Pediatrics, Orthopedics, Urology, ENT, Oncology, Neurosurgery, Gastroenterology, and Critical Care, the hospital provides 24/7 emergency services, a fully equipped ICU, and dialysis services. Anish Multispeciality Hospital also participates in government healthcare schemes like MJPJAY and PMJAY, ensuring access to quality treatment for all.
              </p>
            </div>
          </div>
        </div>

        <div className="md:w-96 bg-gradient-to-r from-[#009F98] to-[#004D9B] text-white py-12 px-8 rounded-lg shadow-lg mt-10 md:mt-0">
          <div className="flex justify-center mb-8">
            <img src={aboutdoctor} alt="About Doctor" className="w-32 h-32 md:w-auto  border-b-4 md:h-full object-cover" />
          </div>

          <div className="flex justify-center">
            <Link to="/contact">
              <button className="bg-red-600 hover:bg-red-700 text-white font-semibold uppercase py-3 px-8 rounded-full shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 duration-300">
                Book An Appointment
              </button>
            </Link>
          </div>

          <div className="mt-12">
            <img src={bgimg} alt="Hospital background" className="w-full h-48 md:h-56 rounded-lg shadow-lg object-cover" />
          </div>
        </div>

      </div>
    </div>
  );
}

export default About;
