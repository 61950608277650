import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div>
      <footer className="bg-zinc-50 text-center text-white dark:bg-cyan-800 dark:text-white lg:text-left">
        <div className="flex items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-white/10 lg:justify-between">
          <div className="me-12 hidden lg:block">
            <span>Get connected with us on social networks:</span>
          </div>
          <div className="flex justify-center space-x-4">
            <div className="w-12 h-12 md:w-12 md:h-12 rounded-full bg-[#009F98] p-4 flex items-center justify-center shadow-lg transition-transform duration-500 ease-in-out hover:scale-110 hover:shadow-xl">
              <FaFacebook />
            </div>
            <div className="w-12 h-12 md:w-12 md:h-12 rounded-full bg-[#009F98] p-4 flex items-center justify-center shadow-lg transition-transform duration-500 ease-in-out hover:scale-110 hover:shadow-xl">
              <FaWhatsapp />
            </div>
            <div className="w-12 h-12 md:w-12 md:h-12 rounded-full bg-[#009F98] p-4 flex items-center justify-center shadow-lg transition-transform duration-500 ease-in-out hover:scale-110 hover:shadow-xl">
              <FaInstagram />
            </div>
            <div className="w-12 h-12 md:w-12 md:h-12 rounded-full bg-[#009F98] p-4 flex items-center justify-center shadow-lg transition-transform duration-500 ease-in-out hover:scale-110 hover:shadow-xl">
              <FaLinkedin />
            </div>
          </div>
        </div>

        <div className="mx-6 py-10 text-center md:text-left">
          <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
            <div>
              <h6 className="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
                <span className="me-3 [&>svg]:h-4 [&>svg]:w-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
                  </svg>
                </span>
                TW Elements
              </h6>
            </div>
            <div className=''>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-center">
                Specialist
              </h6>
              <div className="flex justify-evenly">
                <div>
                  <p className="mb-4"><a href="#!">Gynecology & Obstetrics</a></p>
                  <p className="mb-4"><a href="#!">Orthopedics</a></p>
                  <p className="mb-4"><a href="#!">Oncosurgery</a></p>
                  <p className="mb-4"><a href="#!">General Surgery</a></p>
                  <p className="mb-4"><a href="#!">Urology</a></p>
                </div>
                <div>
                  <p className="mb-4"><a href="#!">Neurosurgery</a></p>
                  <p className="mb-4"><a href="#!">Pediatrics</a></p>
                  <p className="mb-4"><a href="#!">ENT</a></p>
                  <p className="mb-4"><a href="#!">Gastroenterology</a></p>
                  <p className="mb-4"><a href="#!">Dialysis & Critical Care</a></p>
                </div>
              </div>
            </div>
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Useful links
              </h6>
             <Link to='/'> <p className="mb-4"><a href="#!">Home</a></p></Link>
             <Link to='/about_hospital'> <p className="mb-4"><a href="#!">About Hospital</a></p></Link>
             <Link to='/about_doctor'> <p className="mb-4"><a href="#!">About Doctors</a></p></Link>
             <Link to='/'>  <p className="mb-4"><a href="#!">Specialist</a></p></Link>
            </div>
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Contact
              </h6>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                    <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                  </svg>
                </span>
                Terwad Road, Kurundwad, Maharashtra 416106
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>
                </span>
                anishmultihospital@gmail.com
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.21 1.42.959 1.42 1.819V19.5a3 3 0 01-3 3h-1.125C7.636 22.5 1.5 16.364 1.5 8.625V7.5a3 3 0 013-3z" clipRule="evenodd" />
                  </svg>
                </span>
                +91 9876543210
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path fillRule="evenodd" d="M12 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM3.75 10.5a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0z" clipRule="evenodd" />
                    <path fillRule="evenodd" d="M11.25 7.5a.75.75 0 01.75-.75h1.5a.75.75 0 110 1.5h-.75v2.75h.75a.75.75 0 010 1.5h-1.5a.75.75 0 110-1.5h.75V8.25h-.75a.75.75 0 01-.75-.75zM12 14.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                  </svg>
                </span>
                Call 108 for ambulance
              </p>
            </div>
          </div>
        </div>
        <div class="bg-black/5 p-6 text-center">
          <span>© 2024 Copyright:</span>
          <a class="font-semibold" href="https://www.businessmantra.info/"
          >Business Mantra
          </  a >
        </div>
      </footer>
    </div>
  );
}

export default Footer;
