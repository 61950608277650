import React from 'react';
import img1 from '../../../img/stethoscope1.png';
import img2 from '../../../img/ambulance.png';
import img3 from '../../../img/doctor1.png';
import img4 from '../../../img/24-hours.png';

function WhyChoose() {
    const data = [
        {
            img: img1,
            head: 'Professional Staff',
            parase: 'Professional staff members are physicians or allied health practitioners who are highly skilled and committed to excellence in patient care.'
        },
        {
            img: img2,
            head: 'Emergency Care',
            parase: 'We offer 24x7 emergency medical response with a team ready to respond swiftly and effectively.'
        },
        {
            img: img3,
            head: 'Qualified Doctors',
            parase: 'Our team of doctors is highly qualified, providing exceptional healthcare services with compassion and care.'
        },
        {
            img: img4,
            head: '24/7 Service',
            parase: 'We provide 24/7 services to ensure that we are available whenever you need us, offering critical care at all times.'
        },
    ];

    return (
        <div className="bg-gray-50 py-16">
            <div className="text-center mb-12">
                <h6 className="text-lg font-semibold text-blue-500 uppercase">Why Choose Us</h6>
                <h1 className="text-4xl font-bold text-gray-800 mt-2">Our Specialties</h1>
                <p className="text-gray-500 mt-4 max-w-2xl mx-auto">We provide top-notch healthcare services with a commitment to excellence and compassion, ensuring the best care for our patients.</p>
            </div>

            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 px-6 lg:px-0">
                {data.map((item, index) => (
                    <div key={index} className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transform hover:scale-105 transition-all duration-300 border-t-4 border-blue-500">
                        <div className="flex justify-center">
                            <div className="w-20 h-20 bg-cyan-50 rounded-full flex items-center justify-center mb-4 -mt-14 ">
                                <img src={item.img} alt={item.head} className="w-3/4 h-3/4 object-contain" />
                            </div>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-center text-gray-800 mb-2">{item.head}</h3>
                            <p className="text-center text-gray-600">{item.parase}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WhyChoose;
