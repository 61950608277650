import React, { useState } from 'react';
import { FaHospital, FaChevronDown, FaChevronUp, FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo from '../../img/anish logo.png';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isSpecialistOpen, setIsSpecialistOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleAboutMenu = () => {
    setIsAboutOpen(!isAboutOpen);
  };

  const toggleSpecialistMenu = () => {
    setIsSpecialistOpen(!isSpecialistOpen);
  };

  return (
    <nav className="bg-white shadow-lg fixed top-0 left-0 w-full z-50 font-sans">
      <div className="container mx-auto flex justify-between items-center p-4">
        <Link to="/">
          <div className="flex items-center">
            <img src={Logo} alt="Anish Multispeciality Hospital Logo" className="h-12" />
            <span className="text-2xl font-bold text-[#004D9B] ml-2">Anish Multispeciality Hospital</span>
          </div>
        </Link>

        <div className="md:hidden">
          <button onClick={toggleMobileMenu} aria-label="Toggle Menu">
            {isMobileMenuOpen ? <FaTimes className="text-2xl text-gray-800" /> : <FaBars className="text-2xl text-gray-800" />}
          </button>
        </div>

        <div className={`md:flex ${isMobileMenuOpen ? 'block' : 'hidden'} absolute md:static top-16 left-0 w-full md:w-auto bg-white md:bg-transparent shadow-md md:shadow-none transition-all duration-300`}>
          <ul className="flex flex-col md:flex-row md:space-x-8 p-4 md:p-0">
            <li className="text-lg text-gray-600 hover:text-blue-600 transition-colors duration-300 cursor-pointer uppercase">
              <Link to="/" className="flex items-center">Home</Link>
            </li>

            <li className="relative group text-lg text-gray-600 hover:text-blue-600 transition-colors duration-300 cursor-pointer uppercase">
              <button
                onClick={toggleAboutMenu}
                className="flex items-center w-full md:w-auto uppercase"
                aria-haspopup="true"
                aria-expanded={isAboutOpen}
              >
                About
                {isAboutOpen ? <FaChevronUp className="ml-1 transition-transform duration-300" /> : <FaChevronDown className="ml-1 transition-transform duration-300" />}
              </button>
              <ul className={`absolute left-0 mt-2 bg-white shadow-md rounded-lg p-4 space-y-2 w-64 transition-all duration-300 ${isAboutOpen ? 'block opacity-100' : 'hidden opacity-0'} ease-in-out`}>
                <li className="hover:text-blue-600 transition-colors duration-300">
                  <Link to="/about_hospital" className="block px-4 py-2">About Our Hospital</Link>
                </li>
                <li className="hover:text-blue-600 transition-colors duration-300">
                  <Link to="/about_doctor" className="block px-4 py-2">About Our Doctors</Link>
                </li>
              </ul>
            </li>

            <li className="relative group text-lg text-gray-600 hover:text-blue-600 transition-colors duration-300 cursor-pointer uppercase">
              <button
                onClick={toggleSpecialistMenu}
                className="flex items-center w-full md:w-auto uppercase"
                aria-haspopup="true"
                aria-expanded={isSpecialistOpen}
              >
                Specialist
                {isSpecialistOpen ? <FaChevronUp className="ml-1 transition-transform duration-300" /> : <FaChevronDown className="ml-1 transition-transform duration-300" />}
              </button>
              <ul className={`absolute left-0 mt-2 bg-white shadow-md rounded-lg p-4 transition-all duration-300 overflow-y-auto max-h-96 ${isSpecialistOpen ? 'block opacity-100' : 'hidden opacity-0'} ease-in-out`}>
                <li className="hover:text-blue-600">
                  <a href="#gynecology" className="block px-4 py-2">
                    <span className="font-semibold">Gynecology & Obstetrics</span>
                    <p className="text-sm text-gray-500">Women's health, childbirth, fertility</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#general-surgery" className="block px-4 py-2">
                    <span className="font-semibold">General Surgery</span>
                    <p className="text-sm text-gray-500">Various types of surgeries</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#pediatrics" className="block px-4 py-2">
                    <span className="font-semibold">Pediatrics</span>
                    <p className="text-sm text-gray-500">Care for infants and children</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#orthopedics" className="block px-4 py-2">
                    <span className="font-semibold">Orthopedics</span>
                    <p className="text-sm text-gray-500">Bone and joint treatment</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#urology" className="block px-4 py-2">
                    <span className="font-semibold">Urology</span>
                    <p className="text-sm text-gray-500">Urinary tract and reproductive health</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#ent" className="block px-4 py-2">
                    <span className="font-semibold">ENT</span>
                    <p className="text-sm text-gray-500">Diagnosis and treatment of ENT conditions</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#oncosurgery" className="block px-4 py-2">
                    <span className="font-semibold">Oncosurgery</span>
                    <p className="text-sm text-gray-500">Cancer-related surgical treatments</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#neurosurgery" className="block px-4 py-2">
                    <span className="font-semibold">Neurosurgery</span>
                    <p className="text-sm text-gray-500">Treatments for brain disorders</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#gastroenterology" className="block px-4 py-2">
                    <span className="font-semibold">Gastroenterology</span>
                    <p className="text-sm text-gray-500">Digestive system disorders</p>
                  </a>
                </li>
                <li className="hover:text-blue-600">
                  <a href="#dialysis-critical-care" className="block px-4 py-2">
                    <span className="font-semibold">Dialysis & Critical Care</span>
                    <p className="text-sm text-gray-500">Treatments for kidney disease</p>
                  </a>
                </li>
              </ul>
            </li>

            <li className="text-lg text-gray-600 hover:text-blue-600 transition-colors duration-300 cursor-pointer uppercase">
              <Link to="/contact" className="flex items-center">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
