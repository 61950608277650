import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin,FaWhatsapp } from 'react-icons/fa'; 
import presidentImage from '../../img/cheerful-doctor-making-notes-looking-away.jpg';
import bgimg from '../../img/dropdown-bg.png';

function About_Doctor() {
  return (
    <div className="p-4 lg:p-16 pt-24 bg-gray-100 min-h-screen">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden p-8 md:mt-24 mt-64"
            style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}

      >
        {/* Doctor's Image & Social Media */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="relative">
            <div className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 mt-0 flex items-center justify-center z-20">
              <img
                src={presidentImage}
                alt="President"
                className="w-48 h-48 mb-4 rounded-full object-cover border-4 border-white"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-36 sm:mt-0">
            <h1 className="text-4xl font-semibold text-gray-700 mb-2">
              Dr. Avinash Kognole
            </h1>
            <p className="text-gray-600 mb-2">MBBS</p>
            <p className="text-gray-600">DGO (Diploma in Gynecology and Obstetrics)</p>

            {/* Social Media Icons */}
            <div className="mt-4 flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="text-blue-600 w-8 h-8 hover:text-blue-800 transition duration-300" />
              </a>
              <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp className="text-green-500 w-8 h-8 hover:text-green-700 transition duration-300" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="text-pink-500 w-8 h-8 hover:text-pink-700 transition duration-300" />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="text-blue-700 w-8 h-8 hover:text-blue-900 transition duration-300" />
              </a>
            </div>
          </div>
        </div>

        {/* Doctor's Information */}
        <div className="text-center mt-20 border-b pb-12">
          <h2 className="text-3xl font-semibold text-gray-700 mb-4">Experience</h2>
          <p className="text-gray-600 leading-relaxed mb-4">
            2.5 years as a Medical Officer in Sindhudurg
          </p>
          <p className="text-gray-600 leading-relaxed mb-4">
            Established practice in Kurundwad on 24th December 2006 with a 10-bedded Anish Maternity & Surgical Hospital
          </p>
          <p className="text-gray-600 leading-relaxed">
            Expanded and shifted to the new Anish Multispeciality Hospital on 6th May 2015
          </p>
        </div>

        {/* Specializations */}
        <div className="mt-12 text-center">
          <h2 className="text-3xl font-semibold text-gray-700 mb-6">Specializations</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 text-left text-gray-700">
            <ul>
              <li className="mb-2">Gynecology & Obstetrics (Gyn/Obst)</li>
              <li className="mb-2">General Surgery</li>
              <li className="mb-2">Pediatrics</li>
            </ul>
            <ul>
              <li className="mb-2">Orthopedics</li>
              <li className="mb-2">Urology</li>
              <li className="mb-2">ENT (Ear, Nose, and Throat)</li>
            </ul>
            <ul>
              <li className="mb-2">Oncosurgery</li>
              <li className="mb-2">Neurosurgery (Newer Department)</li>
              <li className="mb-2">Gastroenterology</li>
            </ul>
            <ul>
              <li className="mb-2">Dialysis and Critical Care</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About_Doctor;
