import React from 'react';

function Contact() {
  return (
    <div>
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              frameBorder="0"
              title="map"
              marginHeight="0"
              marginWidth="0"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15287.99155440507!2d74.5896417!3d16.6769891!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc0e07448cb4c15%3A0xbd53b5530e80ba20!2sAnish%20Multispeciality%20Hospital!5e0!3m2!1sen!2sin!4v1726900821468!5m2!1sen!2sin" 
            ></iframe>
            <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-[#004D9B] tracking-widest text-xs">ADDRESS</h2>
                <p className="mt-1">Terwad Road, Kurundwad, Maharashtra 416106</p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-[#004D9B] tracking-widest text-xs">EMAIL</h2>
                <a href="mailto:example@email.com" className="text-gray-900 leading-relaxed">anishmultihospital@gmail.com</a>
                <h2 className="title-font font-semibold text-[#004D9B] tracking-widest text-xs mt-4">PHONE</h2>
                <p className="leading-relaxed">+91 9511834123</p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                      <h2 className="text-[#004D9B] text-2xl font-bold mb-4">
              Book an Appointment with Our Expert Doctors
            </h2>

            {/* Name Field */}
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full bg-gray-100 rounded-lg border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-2 px-4 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* Email Field */}
            <div className="relative mb-4">
              <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full bg-gray-100 rounded-lg border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-2 px-4 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* Mobile Number Field */}
            <div className="relative mb-4">
              <label htmlFor="mobile" className="leading-7 text-sm text-gray-600">Mobile Number</label>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                className="w-full bg-gray-100 rounded-lg border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-2 px-4 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* Dropdown List for Selecting Doctor */}
            <div className="relative mb-4">
              <label htmlFor="doctor" className="leading-7 text-sm text-gray-600">Select Doctor</label>
              <select
                id="doctor"
                name="doctor"
                className="w-full bg-gray-100 rounded-lg border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-2 px-4 leading-8 transition-colors duration-200 ease-in-out"
              >
                <option value="">Select Doctor</option>
                <option value="dr-smith">Dr. Smith</option>
                <option value="dr-jones">Dr. Jones</option>
                <option value="dr-williams">Dr. Williams</option>
              </select>
            </div>

            {/* Message Field */}
            <div className="relative mb-4">
              <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-gray-100 rounded-lg border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-2 px-4 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>

            {/* Submit Button */}
            <button className="text-white bg-[#004D9B] border-0 py-2 px-8 focus:outline-none hover:bg-[#1c4e80] rounded-lg text-lg">
              Submit
            </button>
          </div>

        </div>
      </section>
    </div>
  );
}

export default Contact;




