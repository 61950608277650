import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Component/Navbar/Navbar';
import Footer from './Component/Contact/Footer';
import About_Hospital from './Component/About/About_Hospital';
import About_Doctor from './Component/About/About_Doctor';

import Contact from './Component/Contact/Contact';
import Home from './Component/Home/Home';



const App = () => {


  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about_hospital' element={<About_Hospital/>} />
          <Route path='/about_doctor' element={< About_Doctor/>} />
          <Route path='/contact' element={<Contact />} />         
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
};

export default App;